import React, { useState } from 'react';
import Header from './Header';
import '../styles/Login.css'
import captcha from '../image/nocaptcha.gif'
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Login = () => {
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handleUsernameFocus = () => {
    setIsUsernameFocused(true);
    setIsPasswordFocused(false);
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
    setIsUsernameFocused(false);
  };

  const handleBlur = () => {
    setIsUsernameFocused(false);
    setIsPasswordFocused(false);
  };

  return (
    <>
    <Header />
    <main>
      <article>
        <div className="container">
          <div className="row">
            <div className="col-6 login_left">
              <b>Guidelines :</b>
              <p>
                Registered Wallet/Book Records Dealer login here to avail of our web-enabled services.
                If you are not an existing Dealer of HSRP, Call Our Oem For Registration  HSRP online Services.
                We recommend that you keep your password confidential. YARYA SEKUR will not be liable for any damages arising due to misuse of the User ID and Password.
                You may change your password as often as you wish and we advice you to do so in your own interest.
              </p>
              <b> Generate your Password Online:</b>
              <p>
                Enter your User ID and registered Mobile Number / Email-ID to receive unique number on your Mobile / Email-ID.
                Validate unique number received on your registered Mobile Number / Email-ID.
                Generate new password and enjoy Order Booking HSRP online Services.
              </p>
            </div>
            <div className="col-6 login_right">
              <h1>Login</h1>
              <p>HSRP Login Screen</p>
              <div>
                <label>User Name</label> <br/>
                <input type="text" placeholder="User Name" className='username' onFocus={handleUsernameFocus} onBlur={handleBlur} style={{ borderBottom: isUsernameFocused ? '2px solid red' : '2px solid white' }}/><br/>
                <label>Password</label> <br/>
                <input type="password" placeholder="Password" className='username' onFocus={handlePasswordFocus}  onBlur={handleBlur} style={{ borderBottom: isPasswordFocused ? '2px solid red' : '2px solid  white' }}/><br/>
              </div>
              <div className='captchaimg'>
                 <img src={captcha} width='90%'/>
              </div>
              <div className='captchaimg d-flex flex-row justify-content-center'>
                <button type='submit' className='btn btn-danger'>Login</button>
              </div>
              <Link to='/forgetpage' className='captchaimg d-flex flex-row justify-content-end' style={{textDecoration:'none',color:'black',fontWeight:'600'}}>Forget Password</Link>
            </div>            
          </div> 
        </div>
      </article>
    </main>
    <Footer />

    </>
  )
}

export default Login
